var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c('v-card',{staticClass:"auth-card"},[_c('v-card-title',{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/account/login"}},[_c('v-img',{staticClass:"me-3",attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1)],1),_c('v-card-text',[_c('p',{staticClass:"text-xl font-weight-semibold text--primary text-center mb-2"},[_vm._v(" Reset Password 🔒 ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Your new password must be different from previously used passwords ")])]),_c('v-card-text',[(_vm.showPwnWarning)?_c('v-alert',{attrs:{"color":"error","text":""}},[_c('p',{staticClass:"font-weight-semibold mb-1"},[_vm._v("Password Not Allowed")]),_c('p',{staticClass:"text-sm mb-3"},[_vm._v(" The password you are trying to use has been blacklisted. Please submit a different one. "),_c('router-link',{staticClass:"text-decoration-none mt-3",attrs:{"to":"/pages/faq"}},[_vm._v(" Learn more. ")])],1)]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit(_vm.passwordResetForm)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"mode":"lazy","name":"New Password","vid":"newPassword","rules":"required|regexStrongPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"newPassword"}},[_vm._v(" New Password ")]),_c('v-text-field',{attrs:{"id":"newPassword","dense":"","outlined":"","clearable":"","type":_vm.isPasswordVisible ? 'text' : 'password',"placeholder":"············","prepend-inner-icon":_vm.icons.mdiLockOutline,"append-icon":_vm.isPasswordVisible
                      ? _vm.icons.mdiEyeOffOutline
                      : _vm.icons.mdiEyeOutline,"error-messages":errors},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.passwordResetForm.password),callback:function ($$v) {_vm.$set(_vm.passwordResetForm, "password", $$v)},expression:"passwordResetForm.password"}})]}}],null,true)})],1),_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"mode":"lazy","name":"Confirm Password","vid":"confirmPassword","rules":"required|confirmPassword:@newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"confirmPassword"}},[_vm._v(" Confirm Password ")]),_c('v-text-field',{attrs:{"id":"confirmPassword","dense":"","outlined":"","clearable":"","type":_vm.isConfirmPasswordVisible ? 'text' : 'password',"placeholder":"············","prepend-inner-icon":_vm.icons.mdiLockOutline,"append-icon":_vm.isConfirmPasswordVisible
                      ? _vm.icons.mdiEyeOffOutline
                      : _vm.icons.mdiEyeOutline,"required":"","error-messages":errors},on:{"click:append":function($event){_vm.isConfirmPasswordVisible = !_vm.isConfirmPasswordVisible}},model:{value:(_vm.passwordResetForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordResetForm, "confirmPassword", $$v)},expression:"passwordResetForm.confirmPassword"}})]}}],null,true)})],1),_c('v-btn',{staticClass:"mt-4",attrs:{"type":"submit","block":"","color":"primary","loading":_vm.loading,"disabled":invalid || _vm.loading}},[_vm._v(" Set New Password ")])],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('router-link',{staticClass:"d-flex align-center text-sm",attrs:{"to":{ name: 'account-login' }}},[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")]),_c('span',[_vm._v("Back to login")])],1)],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}}),_c('v-img',{staticClass:"auth-tree",attrs:{"width":"247","height":"185","src":require("@/assets/images/misc/tree.png")}}),_c('v-img',{staticClass:"auth-tree-3",attrs:{"width":"377","height":"289","src":require("@/assets/images/misc/tree-3.png")}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }